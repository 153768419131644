import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Parallax } from "react-parallax";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import crowd from "assets/img/events/wls-2020/crowd.webp";
import Section from "../../components/Section";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../../components/Headline";
import VimeoEmbed from "../../components/VimeoEmbed";
import Scroller from "../../components/Scroller";

export default class WLS2020 extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }
  
  render() {
    return ( 
      <Layout transparentNav={true}>
        <Seo title="Off The Map - WLS 2020" />
        <Section className="wls2020TopSection text-center">
          <StaticImage height="300" placeholder="none" src="../../assets/img/events/wls-2020/WLS-Logo-White.webp" alt="Warrior Leadership Summit"></StaticImage>
          <h1>ONLINE EVENT</h1>
          <h4>June 29 - July 3<br/>8 PM CST</h4>
          <Button href="#sessions" onClick={Scroller.handleAnchorScroll} className="mt-3">Watch Now</Button>
        </Section>
        <Parallax className="wls2020MapSection" blur={0} bgImage={crowd} bgImageAlt="crowd" strength={400}>
          <Container >
            <Row className="py-5 align-items-center">
              <Col xs={7} sm={6}>
                <p className="fancy">There are so many roads others tell you to take. But look where they can end up. 
                Suddenly you're trapped in a life you don't really want to be in.<br/>
                Then comes this Voice calling you off the map that goes nowhere. "Follow Me," Jesus says. 
                "To the more you're made for." Millions have. And found a life bigger than they ever dreamed.0
                A future worth living for.</p>
                <p className="fancy">You're invited to join together with Native young people from across this continent, 
                to explore the road to a future without the regrets, without the shame. The journey begins 
                here, at Warrior Leadership Summit.</p>
              </Col>
              <Col xs={5} sm={6}>
                <StaticImage placeholder="none" src="../../assets/img/events/wls-2020/Off-the-Map.png" alt="Off the Map"></StaticImage>
              </Col>
            </Row>
          </Container>
        </Parallax>
        <Section className="wls2020VideoSection dark">
          <Container id="sessions">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary" center={true}>WLS Sessions</Headline>
                The live broadcasts have ended. These rebroadcasts do not include the live concert 
                from the guest artist, and the giveaway items have already been awarded to the live audience winners.
              </Col>
            </Row>
            <Row className="py-5 align-items-center text-center">
              <Col xs={12} md={4}><VimeoEmbed vimeoId="433155899" title="Night 1" /><h3 className="pt-1">NIGHT 1</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="433162727" title="Night 2" /><h3 className="pt-1">NIGHT 2</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="433063142" title="Night 3" /><h3 className="pt-1">NIGHT 3</h3></Col>
            </Row>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={4}><VimeoEmbed vimeoId="433063404" title="Night 4" /><h3 className="pt-1">NIGHT 4</h3></Col>
              <Col xs={12} md={4}><VimeoEmbed vimeoId="432983235" title="Night 5" /><h3 className="pt-1">NIGHT 5</h3></Col>
            </Row>
            <Row className="py-5 justify-content-center align-items-center text-center">
              <Col xs={5} md={3}><Button href="/wls-2020-notebook.pdf" download="WLS 2020 Notebook.pdf" className="px-2" variant="secondary">Notebook</Button></Col>
              <Col xs={5} md={3}><Button href="/hope" className="px-4">Hope</Button></Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2020ArtistsSection">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary" center={true}>Artists</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.artist1)} alt="Building 429" />
              </Col>
              <Col xs={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.artist2)} alt="Lecrae" />
              </Col>
              <Col xs={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.artist3)} alt="We Are Messengers" />
              </Col>
              <Col xs={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.artist4)} alt="KB" />
              </Col>
              <Col xs={2} className="px-1">
                <GatsbyImage image={getImage(this.props.data.artist5)} alt="Mandisa" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="wls2020SpeakersSection dark">
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={10}>
                <Headline className="tertiary" center={true}>Speakers</Headline>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} sm={3} md={4} lg={4}>
              </Col>
              <Col xs={9} sm={9} md={8} lg={7}>
                <Container>
                  <Row className="dot-left mt-3 pl-4 pb-5">
                    <Col>
                      <h5>Nick Liew</h5>
                      <div><em>Jicarilla Apache / Chinese</em></div>
                      <div>On Eagles' Wings Coordinator; Oklahoma State alum; Married for 3 ½ years + one beautiful little girl; Texas pro sports fan</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Weston Francis</h5>
                      <div><em>Navajo</em></div>
                      <div>On Eagles' Wings staff member; Indian Bible College alum; Yankees fan; loves cheesecake</div>
                    </Col>
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Ron Hutchcraft</h5>
                      <div>On Eagles' Wings co-founder; National radio host; known as 'Papa Hutch' across Native America</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Seth &amp; Sarah Stevens</h5>
                      <div><em>Navajo</em></div>
                      <div>Staff members with Across Nations; Chaplains with Navajo Nation; Part of WLS Worship team; Married in 2019</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Brad Hutchcraft</h5>
                      <div>On Eagles' Wings Director; worked with youth on Hopi reservation for 14 years; Married for 17 years + three amazing children; Yankees fan</div>
                    </Col>              
                  </Row>
                  <Row className="dot-left pl-4 pb-5">
                    <Col>
                      <h5>Bobby Dean</h5>
                      <div><em>Tonawanda Seneca</em></div>
                      <div>Pastor on his home reservation; On Eagles' Wings advisor; Married for 45 years!; "Roll Tide" (he's a 'Bama fan)</div>
                    </Col>              
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker1)} alt="Nick Liew" />
              </Col>
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker2)} alt="Weston Francis" />
              </Col>
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker3)} alt="Ron Hutchcraft" />
              </Col>
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker4)} alt="Seth & Sarah Stevens" />
              </Col>
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker5)}alt="Brad Hutchcraft" />
              </Col>
              <Col xs={3} className="px-1">
                <GatsbyImage image={getImage(this.props.data.speaker6)} alt="Bobby Dean" />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={2} sm={3} md={4} lg={4}>
              </Col>
              <Col xs={9} sm={9} md={8} lg={7}>
                <Container>
                  <Row className="dot-bottom mt-4 pl-4 pb-5">
                    <Col>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    artist1: file(relativePath: { eq: "events/wls-2020/B429.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    artist2: file(relativePath: { eq: "events/wls-2020/Lecrae.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    artist3: file(relativePath: { eq: "events/wls-2020/We-Are-Messengers.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    artist4: file(relativePath: { eq: "events/wls-2020/KB.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
    artist5: file(relativePath: { eq: "events/wls-2020/Mandisa.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker1: file(relativePath: { eq: "events/wls-2020/Nick-Liew.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker2: file(relativePath: { eq: "events/wls-2020/Weston-Francis.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker3: file(relativePath: { eq: "events/wls-2020/Ron-Hutchcraft.png" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker4: file(relativePath: { eq: "events/wls-2020/Seth-and-Sarah.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker5: file(relativePath: { eq: "events/wls-2020/Brad-Hutchcraft.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }    
    speaker6: file(relativePath: { eq: "events/wls-2020/Bobby-Dean.jpg" }, sourceInstanceName: {eq: "images"}) {
      childImageSharp {
        gatsbyImageData(
          width: 300 
          height: 300
          )
      }
    }
  }
`
